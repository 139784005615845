import React from 'react'
import Carousel from 'nuka-carousel'
import styled from 'styled-components'
import LeftIcon from './carousel-left-icon'
import RightIcon from './carousel-right-icon'
import Previous from './carousel-previous'
import Next from './carousel-next'

const StyledCarousel = styled(Carousel)`
  width: auto !important;
`

export default ({ children }) => (
  <StyledCarousel
    renderBottomCenterControls={() => null}
    heightMode={'max'}
    pauseOnHover={true}
    slidesToShow={3}
    speed={600}
    transitionMode={'scroll3d'}
    opacityScale={0.20}
    slideWidth={2.75}
    autoplayInterval={4000}
    autoplay={true}
    wrapAround={true}
    renderCenterLeftControls={({ previousSlide }) => (
      <Previous onClick={previousSlide}>
        <LeftIcon />
      </Previous>
    )}
    renderCenterRightControls={({ nextSlide }) => (
      <Next onClick={nextSlide}>
        <RightIcon />
      </Next>
    )}
  >
    {children}
  </StyledCarousel>
)