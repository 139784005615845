import React from 'react'
import ServiceCard from './service-card'
import Carousel from './carousel-3d'
import v4  from 'uuid'

export default ({ list }) => {
  return (
    <Carousel>
      {list.map(
        ({
          slug,
          service: {
            title,
            description,
            path: { url }
          }
        }) => {
          const htmlAst = description.childMarkdownRemark.htmlAst

          return <ServiceCard key={`${v4()}`} title={title} url={`${url}/${slug}`} ast={htmlAst} />
        }
      )}
    </Carousel>
  )
}