import React from 'react'
import styled from 'styled-components'
import Ast from './ast'
import ButtonFull from './button-full'
import Heading from './elements/heading4'
import RowReverse from './row-reverse'

const StyledHeading = styled(Heading)`
  margin-top: 0;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`

const StyledButtonFull = styled(ButtonFull)`
  margin-bottom: 0;
`

const StyledRowReverse = styled(RowReverse)`
  margin-bottom: 0;
`

const Card = styled.div`
  padding: ${({ theme }) => theme.verticalRythm[9]};
  background-color: ${({ theme }) => theme.colors.body};
  border-radius: ${({ theme }) => theme.radii[1]};
  box-shadow: 0 0 5px 0 ${({ theme }) => theme.colors.gray[100]};
  transition: box-shadow ${({ theme }) => theme.timing.fast} ${({ theme }) => theme.transitions.default};
  will-change: box-shadow;

  &:hover {
    box-shadow: 0 5px 10px 0 ${({ theme }) => theme.colors.gray[300]};
  }
`

export default ({ title, url, ast }) => (
  <Card>
    <StyledHeading as="h3">{title}</StyledHeading>
    <Ast ast={ast} />
    <StyledRowReverse>
      <StyledButtonFull to={url} title={title}>
        Learn more
      </StyledButtonFull>
    </StyledRowReverse>
  </Card>
)
