import styled from 'styled-components'
import Button from './styles/button'

const breakpointMd = ({ theme }) => `${theme.media.md}`

export default styled.button`
  ${Button}

  display: none;

  ${breakpointMd} {
    position: relative;
    display: inline-block;
    right: -${({ theme }) => theme.verticalRythm[11]};
  }
`